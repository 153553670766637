'use strict';
(function () {
// onload jquerry check if class exist
// then ajax to endpont and append to div class

    /**
     * Embed script to page
     *  @param {*} container container
     */
    function embedScript(container) {
        try {
            const scriptTag = document.createElement('script');
            scriptTag.src = container.dataset.scriptUrl;
            if (scriptTag.src) {
                container.appendChild(scriptTag);
            }
        } catch (e) {
            // intentionally left empty
        }
    }

    /**
     * Inject html into the container if it exists
     * @param {*} containerArgs container
     * @param {*} id id
     */
    async function initImageTagger(containerArgs, id) {
        const container = containerArgs;
        const url = document.getElementById('shoppable-images-init-script').dataset.url;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    imageTaggerId: id
                })
            });

            const data = await response.text();
            var booleanData = Boolean(data);
            container.classList.add('loaded');

            if (booleanData) {
                container.innerHTML = data;
                const imageTagger = container.querySelector('.js-imgtagger-image');
                if (imageTagger) {
                    embedScript(imageTagger);
                }
            }
        } catch (error) {
            // intentionally left empty
        }
    }

    /**
     * Initialize cache events
     */
    function initializeEvents() {
        const taggerWidget = document.querySelectorAll('.osf-img-tagger-widget');
        const imageTagger = document.querySelector('.js-imgtagger-image');

        if (taggerWidget.length) {
            taggerWidget.forEach(widget => {
                const observer = new window.IntersectionObserver(([entry], observerInstance) => {
                    if (entry.isIntersecting && !widget.classList.contains('loaded')) {
                        initImageTagger(widget, widget.dataset.configId);
                        observerInstance.unobserve(widget);
                    }
                }, {
                    root: null,
                    threshold: 0.1
                });

                observer.observe(widget);
            });
        }

        if (taggerWidget.length === 0 && imageTagger) {
            embedScript(imageTagger);
        }

        $(document).on("click", function(e) {
            if($(e.target).parents(".bookmark-tag").length === 0) {
                $(".bookmark-content").addClass("hide");
            }
        });
    }

    var imagetagger = {
        init: function () {
            initializeEvents();
        }
    };

    imagetagger.init();
}());
